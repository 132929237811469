import type { CSSProperties } from "react";
import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Box, FormHelperText, FormLabel, useTheme } from "@mui/material";
import type { RichTextEditorProps } from "@src/types";
import { AssetContentFieldStatus } from "@src/types";
import { colors } from "@theme/theme";
import { getFieldColorMapping } from "@utils/fonctions.utils";

const RichTextEditor = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  status,
  disabled,
  maxHeight,
}: RichTextEditorProps) => {
  const theme = useTheme();
  const editor = useRef<ReactQuill>(null);
  const [isFocused, setIsFocused] = useState(false);

  const selectedColor = theme.palette?.primary?.main;
  const defaultBorderColor = theme.palette?.secondary?.light;
  const errorColor = theme.palette?.error?.main;

  const getBorderColor = (): string => {
    if (error) return errorColor;
    if (isFocused) {
      return status ? getFieldColorMapping(status, "focus") : selectedColor;
    }
    if (status) {
      return status === AssetContentFieldStatus.NULL
        ? defaultBorderColor
        : getFieldColorMapping(status, "default");
    }
    return defaultBorderColor || "";
  };

  const rootTheme = theme.components?.MuiOutlinedInput?.styleOverrides
    ?.root as CSSProperties; // otherwise border radius is not find

  const handleBlur = (
    range: ReactQuill.Range,
    source: "api" | "user" | "silent",
    quill: ReactQuill.UnprivilegedEditor,
  ) => {
    // add set timeout as workaround to avoid blur when pasting text
    // see https://github.com/zenoamaro/react-quill/issues/276
    setTimeout(() => {
      const fixRange = editor.current?.getEditorSelection();
      if (fixRange) {
        const editorInstance = editor.current?.getEditor();
        if (editorInstance) {
          editorInstance.setContents(editorInstance.getContents());
          editorInstance.setSelection(fixRange);
        }
      } else {
        // True blur
        setIsFocused(false);
        if (onBlur) onBlur(range, source, quill);
      }
    }, 100);
  };

  return (
    <Box
      sx={{
        "& .custom-quill": {
          borderWidth: isFocused ? "2px" : "1px",
          borderStyle: "solid",
          borderRadius: rootTheme?.borderRadius,
          borderColor: getBorderColor(),
          color: disabled ? colors.gray2 : "inherit",
          backgroundColor: theme.palette?.background.default,
          "&:hover": disabled
            ? {}
            : {
                borderColor:
                  status && status !== AssetContentFieldStatus.NULL
                    ? getFieldColorMapping(status, "hover")
                    : error
                      ? errorColor
                      : selectedColor,
              },
        },
        "& .ql-container": {
          fontFamily: "Futura, sans-serif",
          borderWidth: "0px",
          fontSize: "14px",
        },
        "& .ql-editor": {
          maxHeight: maxHeight || "100%",
          overflow: "scroll",
        },
        "& .ql-toolbar": {
          fontFamily: "Futura, sans-serif",
          borderWidth: "0px",
          fontSize: "14px",
          borderBottom: "1px solid " + defaultBorderColor,
          // prevent a bug and textfield is not displayed at all
          // it's not possible to disable directly
          display: disabled ? "none" : "inherit", //
        },
      }}
    >
      {label && (
        <FormLabel
          sx={{ color: disabled ? theme.palette.action.disabled : "auto" }}
        >
          {label}
        </FormLabel>
      )}
      <ReactQuill
        ref={editor}
        readOnly={disabled}
        value={value || ""}
        onChange={onChange}
        onFocus={() => {
          if (!disabled) {
            setIsFocused(true);
          }
        }}
        preserveWhitespace
        onBlur={handleBlur}
        modules={{
          toolbar: [
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [],
          ],
        }}
        style={{ marginBottom: error ? "8px" : "0" }}
        className="custom-quill"
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};

export default RichTextEditor;
